import React from 'react';
import { Container, NavLink } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import Slider from "react-slick";
import StarIcon from '@mui/icons-material/Star';
import google_icon from "../../Assets/images/google_icon.svg";
import rcs_quote_new from "../../Assets/images/home/quote.png";
import yelp_icon from "../../Assets/images/home/yelp.png";
import dummy_user from "../../Assets/images/home/dummy_user.png";
import { useHistory } from 'react-router-dom';
import { client_name } from '../../Helpers/request';
import TitleShape from "../../Assets/images/platinum/line-black.png"

const Testimonial = () => {
    const history = useHistory();
    var settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const reviewData = [
        {
            reviewName:"Kim Suz",
            reviewText:"Everyone is friendly and atmosphere amazing. Kathy super kind and patient and Eddie the repairman does excellent work. ",
            reviewImg: dummy_user,
        },
        {
            reviewName:"Ilana",
            reviewText:"I’m in love with this place and can recommend it to everybody. Very nice staff ! I came with my husband to order engagement rings. We had no much time it was a last minute decision.",
            reviewImg: dummy_user,
        },
        {
            reviewName:"Irina Bykova",
            reviewText:"So glad I stopped into Alicia's. I'd been passing by for years but assumed it was overpriced and possibly unfriendly. I was wrong! Prices are very fair and I was impressed with the level of service. ",
            reviewImg: dummy_user,
        },
        {
            reviewName:"Mikz J",
            reviewText:"Brought in 2 fossil watch for battery replacement… reasonable price. It was done in less than 15mins. The wonderful lady that write me the receipt has a great sense of humor. Thank you!",
            reviewImg: dummy_user,
        },
        {
            reviewName:"Aldie Levine",
            reviewText:"The staff here is helpful and kind. I came here for a repair and ended up buying a ring. There was an issue with the sizing and they quickly resized/fixed it for me. ",
            reviewImg: dummy_user,
        },
     
    ]
    return (
        <>
            <div className="rcs_testimonials">
                <Container>
                       <div className="sj_title_main" >
                                <h2> What People Say  </h2>
                                <img src={TitleShape} alt="Heading Img"/>
                            </div>
                    <Slider className="rcs_testimonial_inner" {...settings2}>
                     {reviewData.map((val, index)=>

                            <div className="rcs_testi_item">
                                                        
                            <img className="rcs_quote" src={rcs_quote_new} alt='quotes' />
                            <div className='d-block'>
                            <p className='mb-1'>{val.reviewText}</p>
                            <a className='read_more' href='https://www.google.com/search?q=alicias+jewelers+ggole+reviews&sca_esv=579127353&rlz=1C1CHZN_enIN1077IN1077&ei=QubmZeXmC_it4-EP7r-y-A8&oq=alicias+jewelers+ggole+review&gs_lp=Egxnd3Mtd2l6LXNlcnAiHWFsaWNpYXMgamV3ZWxlcnMgZ2dvbGUgcmV2aWV3KgIIADIHECEYChigAUieKFDAEFiRGXABeACQAQCYAfEBoAGgBqoBBTAuMi4yuAEByAEA-AEBmAIFoAK2BsICCxAAGIkFGKIEGLADwgILEAAYgAQYogQYsAOYAwCIBgGQBgSSBwUxLjIuMqAH3Aw&sclient=gws-wiz-serp#lrd=0x89c28a7c53b7c883:0xc83e428ecbf8e95f,1,,,,' target='_blank' >Read more ...</a>
                            </div>
                           
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=alicias+jewelers+ggole+reviews&sca_esv=579127353&rlz=1C1CHZN_enIN1077IN1077&ei=QubmZeXmC_it4-EP7r-y-A8&oq=alicias+jewelers+ggole+review&gs_lp=Egxnd3Mtd2l6LXNlcnAiHWFsaWNpYXMgamV3ZWxlcnMgZ2dvbGUgcmV2aWV3KgIIADIHECEYChigAUieKFDAEFiRGXABeACQAQCYAfEBoAGgBqoBBTAuMi4yuAEByAEA-AEBmAIFoAK2BsICCxAAGIkFGKIEGLADwgILEAAYgAQYogQYsAOYAwCIBgGQBgSSBwUxLjIuMqAH3Aw&sclient=gws-wiz-serp#lrd=0x89c28a7c53b7c883:0xc83e428ecbf8e95f,1,,,,", "_blank")} to='#'>
                                <img className="rcs_usercircle" src={val.reviewImg} alt='user'
                                 />
                                <div className="rcs_testi_user"> <h4>{val.reviewName}</h4> </div> </NavLink>

                                <NavLink className='text-center' onClick={() => window.open("https://www.google.com/search?q=alicias+jewelers+ggole+reviews&sca_esv=579127353&rlz=1C1CHZN_enIN1077IN1077&ei=QubmZeXmC_it4-EP7r-y-A8&oq=alicias+jewelers+ggole+review&gs_lp=Egxnd3Mtd2l6LXNlcnAiHWFsaWNpYXMgamV3ZWxlcnMgZ2dvbGUgcmV2aWV3KgIIADIHECEYChigAUieKFDAEFiRGXABeACQAQCYAfEBoAGgBqoBBTAuMi4yuAEByAEA-AEBmAIFoAK2BsICCxAAGIkFGKIEGLADwgILEAAYgAQYogQYsAOYAwCIBgGQBgSSBwUxLjIuMqAH3Aw&sclient=gws-wiz-serp#lrd=0x89c28a7c53b7c883:0xc83e428ecbf8e95f,1,,,,", "_blank")} to='#'>
                                <img class="google_rev_img" src={google_icon} alt='googelicon' /> </NavLink>
                            </div>
                     )}   
                        
                        {/* <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.yelp.com/biz/InnQkf4j02mnQUXJOtqqJw?hrid=_ZaWFPeDH1lUhWrNJZ6zrg&rh_ident=sam&rh_type=phrase/", "_blank")} to='#'>
                            <img class="google_rev_img" src={yelp_icon} /> </NavLink>
                            <img className="rcs_quote" src={rcs_quote_new} />
                            <p>As a native Charlottean, I've been to many various jewelers thru the years for jewelry repair, watch batteries, ring cleaning, selling gold,
                                and having custom jewelry designed. ...
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.yelp.com/biz/InnQkf4j02mnQUXJOtqqJw?hrid=_ZaWFPeDH1lUhWrNJZ6zrg&rh_ident=sam&rh_type=phrase", "_blank")} to='#'>
                                <img className="rcs_usercircle" src={dummy_user} />
                            </NavLink>
                            <div className="rcs_testi_user"> <h4>Emily H.</h4> </div>
                        </div> */}
                        {/* <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.yelp.com/biz/InnQkf4j02mnQUXJOtqqJw?hrid=u-niHA_hXScxw3NZ1H78iA&rh_type=phrase&rh_ident=asaad", "_blank")} to='#'>
                                <img class="google_rev_img" src={yelp_icon} /> </NavLink>
                            <img className="rcs_quote" src={rcs_quote_new} />
                            <p>This is my favorite jewelry store in Charlotte! The staff is very friendly and the owner, Asaad, goes above and beyond to help. They have a huge selection of engagement rings...
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.yelp.com/biz/InnQkf4j02mnQUXJOtqqJw?hrid=u-niHA_hXScxw3NZ1H78iA&rh_type=phrase&rh_ident=asaad", "_blank")} to='#'>
                                <img className="rcs_usercircle" src={dummy_user} /> </NavLink>
                            <div className="rcs_testi_user"> <h4>Scarlett S.</h4> </div>
                        </div> */}
                        
                    </Slider>
                    {/* <div className="rcs_google_review"> <div className="text-center" onClick={() => window.open("https://www.google.com/search?q=malak+jewelers&oq=malak+jewelers&aqs=chrome..69i57j46i175i199i512j0i22i30j69i60l3.1976j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88542710fc7ccdd7:0x70a87d37ffa60fdd,1,,,", "_blank")}> <span className="nowrap"><strong>Google</strong>  rating score: </span><span className="nowrap"><strong>5.0</strong> of 5, </span><span className="nowrap">based on <strong>965 reviews</strong>.</span> </div> </div> */}
                </Container>
            </div>
        </>
    )
}

export default Testimonial;