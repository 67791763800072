import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/Assets/css/custome.css';
import '../src/Assets/css/color.css';
import '../src/Assets/css/Education/style.css';
import Home from './Containers/Front/Home';
import React from 'react';
import { Suspense } from 'react';
import { Skeleton } from '@mui/material';
import MainRequest from './Components/mainRequest';
import { base_url, isLogin, postHeader } from './Helpers/request';
import { useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { persistor } from './Store/store';
import { isDesktop } from 'react-device-detect';
import Education from './Containers/Education/education';

const Mainrouter = React.lazy(() => import('./mainrouter'));
// const MainRequest = React.lazy(() => import('./Components/mainRequest'));
const MegaMenu = React.lazy(() => import('./Containers/Header/MegaMenu'));
const Header = React.lazy(() => import('./Containers/Header/MegaMenu'));
const Footer = React.lazy(() => import('./Containers/Footer/footer'));

const App = () => {
  return (
    <>
      <BrowserRouter>
      <MainRequest />
        <Suspense fallback={<><Skeltonheader /></>}>
          <Header  />
        </Suspense>
        <Switch>
          <Route exact path="/" component={Home} />
          <Suspense fallback={<><Skelton /></>}>
            <Route path='*' exact={true} component={Mainrouter} />
            {/* <Route path="/education" component={Education} /> */}
          </Suspense>
        </Switch>
        <Suspense fallback={<><Skelton /></>}>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </>
  )
}

// skelton conponent
const Skelton = () => {
  return (<>
    <div className='sk' style={{ margin: "15px 0px" }}>
      <Skeleton variant="text" animation="wave" />
    </div>
  </>)
}
const Skeltonheader = () => {
  return (<>
    <div className='skh' style={{ margin: "0px 0px 10px 0px" }}>
      <Skeleton variant="text" animation="wave" height={75} />
    </div>
  </>)
}
export default App;